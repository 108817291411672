define("ember-elsewhere/services/ember-elsewhere", ["exports", "@ember/service", "@ember/runloop", "@ember/object", "@ember/array", "@glimmer/tracking"], function (_exports, _service, _runloop, _object, _array, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let _class = _exports.default = (_class2 = class _class2 extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "actives", _descriptor, this);
      _defineProperty(this, "_alive", {});
      _defineProperty(this, "_counter", 1);
    }
    show(sourceId, name, component, outsideParams) {
      let order = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
      // if current component has specific order that is greater than current internal count
      // update internal count so any subsequent component that does not provide order will
      // be after.
      if (this._counter < order) {
        this._counter = order + 1;
      }
      this._alive[sourceId] = {
        target: name || 'default',
        component,
        order: order || this._counter++,
        outsideParams
      };
      this._schedule();
    }
    clear(sourceId) {
      delete this._alive[sourceId];
      this._schedule();
    }
    _schedule() {
      (0, _runloop.scheduleOnce)('afterRender', this, this._process);
    }
    _process() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      let newActives = {};
      let alive = this._alive;
      Object.keys(alive).forEach(sourceId => {
        let {
          target,
          component,
          order,
          outsideParams
        } = alive[sourceId];
        newActives[target] = newActives[target] || (0, _array.A)();
        let newActive = component ? {
          component,
          order,
          outsideParams
        } : null;
        newActives[target].push(newActive);
      });
      Object.keys(newActives).forEach(target => {
        newActives[target] = (0, _array.A)(newActives[target].sortBy('order'));
      });
      this.actives = _object.default.create(newActives);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "actives", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _object.default.create();
    }
  }), _class2);
});